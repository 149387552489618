<template>
  <div id="app">
    <Loader v-if="loading" />
    <div v-else :class="[pageClass]">
      <Header />
      <Sidebar
        v-click-away="toggleSidebar"
        :class="{ active: toggleSidebar, 'd-none': landingPage }"
      />
      <transition>
        <main>
          <transition-page>
            <keep-alive><router-view /></keep-alive>
          </transition-page>
        </main>
      </transition>
      <Footer />
      <SponsorCodeModal v-if="showSponsorModal" />
    </div>
    <Schema :schemaData="schemaData" />
  </div>
</template>

<script>
import Cookie from '@/helpers/cookies'
import FidesScript from '@/helpers/fides'
import { mapGetters } from 'vuex'
import { Header, Footer, Sidebar } from '@/components/layout'
import TransitionPage from '@/components/common/TransitionPage'
import SponsorCodeModal from '@/components/common/SponsorCodeModal'
import Schema from '@/components/common/Schema'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$route.meta.pageTitle,
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          name: 'description',
          content: this.$route.meta.pageDescription,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:locale',
          property: 'og:locale',
          content: 'en_us',
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.$route.meta.pageTitle,
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://www.scoremaster.com/ScoreMaster.png',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.$route.meta.pageDescription,
        },
        {
          vmid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary',
        },
        {
          vmid: 'twitter:site',
          property: 'twitter:site',
          content: '@scoremaster',
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: this.$route.meta.pageTitle,
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: this.$route.meta.pageDescription,
        },
        {
          vmid: 'twitter:image',
          property: 'twitter:image',
          content: 'https://www.scoremaster.com/ScoreMaster.png',
        },
        {
          vmid: 'twitter:image:src',
          property: 'twitter:image:src',
          content: 'https://www.scoremaster.com/ScoreMaster.png',
        },
        {
          vmid: 'layout',
          property: 'layout',
          content: 'page',
        },
      ],
    }
  },
  components: {
    Header,
    Sidebar,
    Footer,
    TransitionPage,
    SponsorCodeModal,
    Schema,
  },
  data() {
    return {
      toggleSidebar: false,
      pageClass: '',
    }
  },
  created() {
    if (!window.__PRERENDER_INJECTED) {
      // Install Fides Script
      FidesScript.install()

      let klaviyoScript = document.createElement('script')
      klaviyoScript.setAttribute(
        'src',
        'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=NfkyyZ'
      )
      klaviyoScript.async = true
      document.body.appendChild(klaviyoScript)

      if (process.env.VUE_APP_SEGMENT_ENABLED === '1') {
        this.initializeSegment()
      }
    }
  },
  mounted() {
    // Set home class on first site load
    if (this.$route.name) this.pageClass = this.$route.name.toLowerCase()

    this.$root.$on('bv::toggle::collapse', target => {
      if (target === 'sidebar') this.toggleSidebar = !this.toggleSidebar
    })
  },
  computed: {
    ...mapGetters({
      loading: 'member/isLoading',
      pidLoaded: 'member/isPIDLoaded',
      validQL: 'member/isValidQL',
      showSponsorModal: 'member/isSponsorModalRequired',
      schemaData: 'metaData/schema',
    }),
    landingPage: function() {
      return this.$route.name === 'LandingPage'
    },
  },
  watch: {
    $route(to, from) {
      if (!from.name) {
        this.initializeTracking(to)
      }

      // Fides Segment identify
      if (process.env.VUE_APP_SEGMENT_ENABLED === '1') {
        window.analytics.identify({
          dataSalesConsent: window.Fides.consent.data_sales_and_sharing,
        })
      }

      this.updatePageClass(to)
      this.toggleSidebar = false
      if (process.env.VUE_APP_SEGMENT_ENABLED === '1') {
        this.$store.dispatch('member/pushSegmentPageView', {
          path: to.path,
          title: to.meta.pageTitle,
          url: window.location.href,
          ...(from.name && {
            referrer: `${window.location.origin}${from.path}`,
          }),
          search: to.fullPath.slice(1),
          timestamp: new Date().toISOString(),
        })
      }
    },
  },
  methods: {
    updatePageClass(to) {
      this.pageClass = to.name.toLowerCase()
    },
    initializeSegment() {
      var analytics = (window.analytics = window.analytics || [])
      if (!analytics.initialize)
        if (analytics.invoked) {
          // do nothing
        } else {
          analytics.invoked = !0
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
          ]
          analytics.factory = function(e) {
            return function() {
              var t = Array.prototype.slice.call(arguments)
              t.unshift(e)
              analytics.push(t)
              return analytics
            }
          }
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e]
            analytics[key] = analytics.factory(key)
          }
          analytics.load = function(key, e) {
            var t = document.createElement('script')
            t.type = 'text/javascript'
            t.async = !0
            t.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              key +
              '/analytics.min.js'
            var n = document.getElementsByTagName('script')[0]
            n.parentNode.insertBefore(t, n)
            analytics._loadOptions = e
          }
          analytics._writeKey = process.env.VUE_APP_SEGMENT_ID
          analytics.SNIPPET_VERSION = '4.15.3'
          analytics.load(process.env.VUE_APP_SEGMENT_ID)
        }
    },
    initializeTracking(to) {
      let [QL, PID] = [null, null]
      // router requests to an unknown path send a quicklink request
      if (to.meta.ql && to.path.split('/')[1]) {
        QL = to.path.split('/')[1]
      } else {
        PID =
          to.query.PID ||
          to.query.pid ||
          Cookie.get('PID') ||
          localStorage.getItem('PID') ||
          process.env.VUE_APP_DEFAULT_PID
      }

      // In the event someone gets to the /special-offer landing page without a pid in the url, we should use the landing page default pid
      if (this.landingPage && PID === process.env.VUE_APP_DEFAULT_PID)
        PID = this.$store.state.member.landing_pid

      this.checkUrlParams(to.query)
      this.$store.dispatch('member/lookupCampaign', {
        PID: PID ? encodeURI(PID) : null,
        QL: QL ? encodeURI(QL) : null,
      })
    },
    checkUrlParams(query) {
      const tokens = ['aid', 'adid', 'tid', 'sid', 'cid']
      const utm_tokens = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
      ]
      const queries = Object.entries(query)

      if (queries.length) {
        // Delete all/any preexisting tracking tokens from local storage
        for (const token of tokens) {
          localStorage.removeItem(token)
        }

        for (let [token, prop] of queries) {
          token = token.toLowerCase()
          if (token !== 'pid' && tokens.findIndex(x => x === token) !== -1) {
            let val = encodeURI(prop)

            localStorage.setItem(token, val)
            this.$store.commit('member/SET_URL_PARAM', { token, val })
          }

          if (utm_tokens.findIndex(x => x === token) !== -1) {
            let val = encodeURI(prop)
            this.$store.commit('member/SET_UTM_PARAM', { token, val })
          }
        }
      } else {
        for (const token of tokens) {
          if (localStorage.getItem(token)) {
            let val = encodeURI(localStorage.getItem(token))
            this.$store.commit('member/SET_URL_PARAM', { token, val })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  padding-top: 70px;
}
#app {
  transition: opacity 0.3s ease;
}
#app.loading {
  opacity: 0;
}
.loader {
  margin: 100px auto;
  display: block;
}
</style>
